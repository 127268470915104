.registration-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10%;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.registration-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group .register-input {
  margin-left: -11px;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.form-group button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-group #registerSubmitButton {
  background-color: rgb(84, 83, 81);
}

.form-group #registerSubmitButton:hover {
  background-color: rgb(101, 100, 97);
}