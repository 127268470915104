.HomePageContainer {
    background-color: #000000;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 20px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
}
  
.Header {
    background-color: #6d625e;
    padding: 20px;
}

.Title {
    font-size: 64px;
    font-weight: 700;
    margin: 0;
}

.Subtitle {
    font-size: 24px;
    font-weight: 400;
    margin-top: 10px;
}

.MainContent {
    flex-grow: 1;
    padding: 20px;
}

.DescriptionTitle {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
}

.DescriptionText {
    font-size: 16px;
    font-weight: 400;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
}

.CTASection {
    margin-top: 20px;
}

.PlayButton {
    background-color: #009688;
    color: #fff;
    padding: 15px 30px;
    font-size: 24px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.PlayButton:hover {
    background-color: #007a6c;
}

.Footer {
    background-color: #6d625e;
    padding: 20px;
}

.FooterText {
    font-size: 14px;
    font-weight: 300;
}