.AccountPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    position: absolute;
}
  
.FormContainer {
    width: 60%;
    margin-bottom: 20px;
}

.ToggleFormButton {
    padding: 10px 20px;
    background-color: #009688;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.ToggleFormButton:hover {
    background-color: #007a6c;
}